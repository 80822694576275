import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/code-factory.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/docs-and-help.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/gitlab.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jfrog.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jira.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/mtr.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/plantuml.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/support.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yam.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yopass.svg");
;
import(/* webpackMode: "eager", webpackExports: ["defaultTranslationValues"] */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa_hie7s3iquqplynstorqacf66kq/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa_hie7s3iquqplynstorqacf66kq/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0.0-rc-fb9a90fa48-20240614__reac_3oppydrhzshrauvez7b6rgn7pi/node_modules/next/dist/client/image-component.js");
